import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import styles from '/src/assets/styles/pages/home.page.scss';

import { getCategories_req, getSlider_req } from '../api/home.api';
import { getDeviceData, isClient } from '../helpers/detector.helper';
import { cacheControl } from '../helpers/cache.helper';
import HomeSection from '../components/sections/home/home.section';
import { getGames_req } from '../api/game.api';
import { setCategories } from '../redux/global.slice';

export default function HomePage({ sliderData, games, page, gamesOnPage }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.global.categories);
  const { locale } = router;

  const getCategories = useCallback(() => {
    try {
      dispatch(setCategories(getCategories_req({}, locale)));
    } catch (e) {
      console.error('HomePage -> getCategories Error', e);
    }
  }, []);

  useEffect(() => {
    if (!categories) {
      getCategories();
    }
  }, [categories]);

  return (
    <>
      <HomeSection
        page={page}
        gamesOnPage={gamesOnPage}
        category={categories?.[0]?.Key || 'top-games'}
        sliderData={sliderData}
        games={games}
      />
      <style jsx>{styles}</style>
    </>
  );
}

HomePage.getInitialProps = async ({ res, req, query }) => {
  const { isMobile } = getDeviceData(req);
  const page = Number(query.page) || 1;
  const gamesOnPage = isMobile ? 30 : 36;

  if (!isClient()) {
    cacheControl(res, 20);
  }

  let sliderData = null;
  let games = null;

  if (!isClient() && req?.url && !req?.url.includes('/_next/')) {
    try {
      sliderData = await getSlider_req({
        Key: 'home_slider',
        IsMobile: isMobile,
      });
    } catch (e) {
      console.log('HomePage -> getServerSideProps -> sliderData Error:', e);
    }

    try {
      games = await getGames_req(
        req.cookies,
        gamesOnPage,
        page,
        null,
        4,
        null,
        isMobile
      );
    } catch (e) {
      console.log('HomePage -> getInitialProps -> games Error:', e);
    }
  }

  return { sliderData, games, page, gamesOnPage };
};
